<svelte:options tag="sign-up" />
<link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'>

<script>
	import { onMount } from 'svelte';
	import { fade, fly } from 'svelte/transition';
	import { nanoid } from 'nanoid';
	const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	let baseUrl = '';
	let saveError = false;
	let notificationMessage = '';
	
	// Section1 Data
	let sharerId = nanoid(12);
	let email='';
	let originalSharerId;

	// Section2 Data
	let interestedAsTalent=false;
	let interestedAsBuyer=false;
	let interestedInHelping=false;
	let interestedInPosts=false;

	// Section3 Data
	let issuesWithWork = '';
	let nKipoExcitement = '';

	let emailError=false;
	let section1Visible = true;
	let section2Visible = false;
	let section3Visible = false;
	let section4Visible = false;

	let copySucceeded = false;
	let copyFailed = false;
	
	$: submissionData = {
		sharerId,
		email,
		baseUrl,
		originalSharerId,
		interestedAsTalent,
		interestedAsBuyer,
		interestedInHelping,
		interestedInPosts,
		issuesWithWork,
		nKipoExcitement
	};
	$: shareLink = `${baseUrl}?og=${sharerId}`;
	
	
	onMount(() => {
		baseUrl = window.location.href.split('?')[0];
		let urlSearch = new URLSearchParams(window.location.search);
		originalSharerId = urlSearch.get('og');
	});
	

	let setSection = (sectionNumber) => {
		section1Visible = sectionNumber == 1 ? true : false;
		section2Visible = sectionNumber == 2 ? true : false;
		section3Visible = sectionNumber == 3 ? true : false;
		section4Visible = sectionNumber == 4 ? true : false;
	};
	
	let submitData = async () => {
		let apiEndpoint = 'https://jdtn9tt2g5.execute-api.us-east-1.amazonaws.com/default/post-nkipo-sign-up';
		console.log(submissionData);
		const response = await fetch(apiEndpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-Api-Key': 'IPYgQmC4hc2puA0gm1lec2GVLuglrFEk5NS0Lpyi'
			},
			body: JSON.stringify(submissionData)
		});
		return await { status: response.status, data: response.json() };
	};

	let submitSection1 = async () => {
		if (email_regex.test(email)) {
			emailError = false;

			gtag('event', 'sign-up', {});
			// TODO: Send to submissionData to server
			let response = await submitData();
			if (response.status === 200) {
				setNotificationMessage("Email registered");
				console.log(response);
				setSection(2);
			}
			else {
				setNotificationMessage("Unknown error. Please retry!", true);
			}
		} else {
			emailError = true;
		}
	};
	let submitSection2 = async () => {
		console.log("Send Section 2 Data");
		
		gtag('event', 'section2-submitted', {});
		let response = await submitData();
		console.log(response);
		setSection(3);
	};
	let submitSection3 = async () => {
		console.log("Send Section 3 Data");

		gtag('event', 'section3-submitted', {});
		let response = await submitData();
		if (response.status === 200) {
			setNotificationMessage("Completed sign-up!");
			console.log(response);
			setSection(4);
		}
		else {
			setNotificationMessage("Unknown error. Please retry!", true);
		}
	};

	let handleShareUrlCopy = () => {
		console.log(navigator.clipboard);
		gtag('event', 'copied-share-url', {});
		navigator.clipboard.writeText(shareLink)
            .then(() => {
                console.log('Copied to clipboard');
				copySucceeded = true;
				copyFailed = false;
            })
            .catch(err => {
                console.log('Could not copy to clipboard:', err);
				copySucceeded = false;
				copyFailed = true;
			});
	};

	let setNotificationMessage = (newMessage, error=false) => {
		saveError = error;
		notificationMessage = newMessage;
		setTimeout(() => { notificationMessage = ''; }, 5200);
	};
</script>

<div id="formSection">
	{#if section1Visible}
	<div id="section1" in:fly="{{ x: 200, duration: 500 }}" out:fly="{{ x: -200, duration: 500 }}">
		<form on:submit|preventDefault>
		<input type="hidden" bind:value="{originalSharerId}"> 
		<input type="email" placeholder="Enter your e-mail" required="required" bind:value="{email}" /><button on:click="{submitSection1}">Join Beta Waitlist</button>

		{#if emailError}
		<div class="error">
			<p>Please enter a valid email address.</p>
		</div>
		{/if}
		</form>
	</div>
	{/if}

	{#if section2Visible}
	<div id="section2" in:fly="{{ x: 200, duration: 500 }}" out:fly="{{ x: -200, duration: 500 }}">
		<div class="section-content">
			<div><lable><input type="checkbox" bind:checked="{interestedAsTalent}" /> I'd be interested as The Talent </lable></div>
			<div><lable><input type="checkbox" bind:checked="{interestedAsBuyer}" /> I'd be interested as The Buyer </lable></div>
			<div><lable><input type="checkbox" bind:checked="{interestedInHelping}" /> I'd be open to a user interview or in contributing in someother way </lable></div>
			<div><lable><input type="checkbox" bind:checked="{interestedInPosts}" /> I'd be interested in notifications for new blog posts </lable></div>
		</div>
		<div class="nav-buttons">
			<button on:click="{() => setSection(1)}">
				Back
			</button>
			<button on:click="{submitSection2}">
				Submit
			</button>
		</div>
	</div>
	{/if}

	{#if section3Visible}
	<div id="section3" in:fly="{{ x: 200, duration: 500 }}" out:fly="{{ x: -200, duration: 500 }}">
		<div class="section-content">
			<label>
				What pains/problems/frustrations are you encountering at work?
				<br />
				<textarea bind:value="{issuesWithWork}"></textarea>
			</label>
			<label>
				What excites you about the idea of nKipo?
				<br />
				<textarea bind:value="{nKipoExcitement}"></textarea>
			</label>
		</div>
		<div class="nav-buttons">
			<button on:click="{() => setSection(2)}">
				Back
			</button>
			<button on:click="{submitSection3}">
				Submit
			</button>
		</div>
	</div>
	{/if}

	{#if section4Visible}
	<div id="section4" in:fly="{{ x: 200, duration: 500 }}" out:fly="{{ x: -200, duration: 500 }}">
		<div class="section-content">
			<span>Share the link below to help us change the way we work!</span>
		</div>
		<div class="nav-buttons">
			<button on:click="{() => setSection(3)}">
				Back
			</button>
		</div>
	</div>
	{/if}
</div>
<div id="shareSection">
	<div>
		Share this link to connect with your friends!
	</div>
	<div>
		<input readonly="readonly" bind:value="{shareLink}"/>
		<button on:click="{handleShareUrlCopy}">
			<i class='fa fa-copy' style="color: teal;"></i>
		</button>
	</div>
	<div>
		{#if copySucceeded}
		<span>Copied!</span>
		{/if}
		{#if copyFailed}
		<span class="error">Error copying, trying doing so manually!</span>
		{/if}
		
	</div>
</div>
{#if notificationMessage}
<div id="sign-up-response-notification"
	style="background: {saveError ? 'rgb(244 67 54);' : 'rgb(76 175 80)'}; position: fixed; top: .25rem; right: .25rem; padding-left: 1rem; padding-right: 1rem; padding-top: .5rem; padding-bottom: .5rem; border: .5px solid #eee; border-radius: 0.5rem; color: white;"
	transition:fade>
	{notificationMessage}
</div>
{/if}

<style>
label {
	display: block;
}

input, button, textarea {
	font-family: inherit;
	font-size: inherit;
	-webkit-padding: 0.4em 0;
	padding: 0.4em;
	margin: 0 0 0.5em 0;
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 2px;
}

input:disabled {
	color: #ccc;
}

button {
	color: #333;
	background-color: #f4f4f4;
	outline: none;
}

button:disabled {
	color: #999;
}

button:not(:disabled):active {
	background-color: #ddd;
}

button:focus {
	border-color: #666;
}

	#formSection {
		height: 14em;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		width: 100%;
	}
	.error {
		color: red;
		position: relative;
		bottom: 1em;
		text-align: center;
	}
	.section-content {
		width: 100%;
		min-height: 11.5em;
		display: flex;
		align-items: stretch;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
		text-align: left;
	}
	.section-content textarea {
		width: 100%;
	}
	.nav-buttons {
		text-align-last: justify;
		width: 100%;
		min-height: 2.5em;
	}
	#shareSection {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
		text-align: center;
		width: 100%;
	}
</style>